.order {
	display: flex;
	padding-bottom: 24px;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	gap: 24px;

	&-title {
		color: var(--tg-theme-text-color);
		width: 100%;
		font-family: 'SF Pro Display';
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px; /* 121.429% */
		letter-spacing: 0.38px;
	}

	&-subtitle {
		color: var(--tg-theme-text-color);
		width: 100%;
		font-family: 'SF Pro Display';
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 128.571% */
		letter-spacing: -0.154px;
	}

	&__block {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
		align-self: stretch;

		&-list {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			align-self: stretch;
		}
	}

	&-item {
		display: flex;
		width: 100%;
		
		justify-content: space-between;
		align-items: center;
		flex: 1 0 0;
		border-radius: 16px;
		background-color: var(--tg-theme-secondary-bg-color);

		&__container{
			display: flex;
			justify-content: space-between;
			padding: 12px 22px 12px 12px;
			border-radius: 16px;
			width: 100%;
		}

		img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}

		&-inner {
			display: flex;
			align-items: center;
			gap: 8px;

			&-speed{
				svg{
					width: 40px;
					height: 40px;
				}

				// path{
				// 	fill: var(--tg-theme-link-color);
				// }
			}
		}

		&-price {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 4px;

			&-title {
				color: var(--tg-theme-text-color);
				font-family: 'SF Pro Display';
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 18px; /* 128.571% */
				letter-spacing: -0.154px;
			}

			&-description {
				color: #8f8fb2;
				font-family: 'SF Pro Display';
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
			}
		}

		&-check {
			display: flex;
			align-items: center;
		}

		&_active {
			@extend .order-item;
			border-radius: 16px;
			border: 2px solid rgba(0, 122, 255, 0.1);
			background-color: var(--tg-theme-bg-color);

			// path {
			// 	fill: var(--tg-theme-button-text-color);
			// }

			.order-item__container{
				border: 1px solid var(--tg-theme-button-color);
				background: var(--tg-theme-bg-color);
			}
		}
	}
}