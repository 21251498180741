@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SF-Pro-Display-Light.woff2') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SF-Pro-Display-Regular.woff2') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SF-Pro-Display-Medium.woff2') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SF-Pro-Display-Semibold.woff2') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SF-Pro-Display-Bold.woff2') format('truetype');
    font-weight: 700;
}