@import '../../styles/variables.scss';

.DAS-input-box {
	// --error-height: 24px;
	--input-height: 48px;
	--textarea-height: 154px;
	--label-height: 20px;
	min-height: calc(var(--input-height) + var(--error-height));
	width: 100%;

	// &-textarea {
	// 	min-height: calc(
	// 		var(--textarea-height) + var(--error-height) + var(--label-height)
	// 	);
	// }
}

.DAS-input {
	display: flex;
	padding: 11px 16px;
	align-items: flex-start;
	gap: 10px;
	flex: 1 0 0;
	box-sizing: border-box;
	min-height: var(--input-height);
	// max-width: 315px;
	width: 100%;
	background-color: var(--tg-theme-bg-color);
	border-radius: 8px;

	&-border {
		border: 1px solid var(--tg-theme-bg-color);
		border-radius: 10px;
	}

	&-non-label {
		padding: 0px 16px;
	}

	&-search {
		gap: 8px;

		&-image {
			cursor: pointer;
		}
	}

	&-label {
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		color: #8d8d8d;
	}

	&-error {
		color: $color-error;
	}

	&-error-box {
		display: flex;
		justify-content: flex-start;
		padding: 0px 0px 0px 16px;
		gap: 10px;
		color: $color-error;
	}

	&-main {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding: 0px;
	}

	&-text {
		color: var(--tg-theme-text-color);
		font-family: 'SF Pro Display';
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px; /* 120% */
		letter-spacing: -0.43px;
		width: 100%;
		border: none;
		outline: none;
		background-color: inherit;
		&::placeholder {
			// color
		}
	}

	&-text-textarea {
		display: flex;
		padding: 11px 16px 11px 0px;
		align-items: flex-start;
		gap: 10px;
		flex: 1 0 0;
		min-height: 112px;
		height: 100%;
		resize: none;
		color: var(--tg-theme-text-color);
		font-family: 'SF Pro Display';
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px; /* 120% */
		letter-spacing: -0.43px;
	}

	&-textarea {
		min-height: 94px;
		padding-right: 0px;
	}
}
