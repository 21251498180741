.radio-group {
	display: grid;
	width: 100%;
	gap: 8px;
	grid-template-columns: 1fr 1fr;

	&-item {
		display: flex;
		width: 100%;
		padding: 7px 24px 8px 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2px;
		border-radius: 12px;
		background: rgba(0, 122, 255, 0.1);
		cursor: pointer;

		svg {
			width: 21px;
			height: 21px;
		}

		path {
			fill: var(--tg-theme-link-color);
		}

		&-title {
			color: var(--tg-theme-link-color);
			text-align: center;
			font-family: 'SF Pro Display';
			font-size: 11px;
			font-style: normal;
			font-weight: 500;
			line-height: 13px; /* 118.182% */
			letter-spacing: 0.06px;
			word-break: break-word;
		}

		&-price {
			color: var(--tg-theme-link-color);
			font-family: 'SF Pro Display';
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: 12px;
		}

		&-count {
			font-family: 'SF Pro Display';
			font-size: 11px;
			font-style: normal;
			font-weight: 500;
			line-height: 11px;
			background: var(
				--2,
				linear-gradient(180deg, #f9a558 0%, #f9c258 100%)
			);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		&_disable {
			@extend .radio-group-item;
			border-radius: 12px;
			opacity: 0.5;
			background: rgba(0, 122, 255, 0.1);
		}

		&_active {
			@extend .radio-group-item;
			background: var(--tg-theme-button-color);

			path {
				fill: var(--tg-theme-button-text-color);
			}

			.radio-group-item-title,
			.radio-group-item-price {
				color: var(--tg-theme-button-text-color);
			}
		}

		&:last-child:nth-child(odd) {
			grid-column: span 2;
		}
	}
}
