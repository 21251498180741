//Colors
$color-primary: #472DF8;
$color-secondary: #FFFFFF;
$color-error: #E74A56;
$color-checkbox-active: #472DF8;
$color-white: #FFFFFF;
$color-dark: #151515;
$color-select-header: #353535;

$font-main: 'SF UI Display', sans-serif;

//Breakpoints
$breakpoint-xxl: 1600px;
$breakpoint-xl: 1366px;
$breakpoint-lg: 768px;
$breakpoint-md: 560px;
$breakpoint-sm: 390px;

//Container max width
$container-max-width: 460px;

//Transitions speed
$accordion-transition-speed: 0.5s;
$tabs-transition-speed: 1s;
$circle-dots-transition-speed: 1.2s;