.order-detail {
	&__block {
		display: flex;
		// padding: 0px 16px 24px 16px;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		align-self: stretch;
	}

	&__theme {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		width: 100%;

		&-item {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 2px 0px;
			align-items: flex-start;
			padding: 11px 16px;
			border-radius: 16px;
			background: var(--tg-theme-bg-color);

			&-category {
				color: var(--tg-theme-text-color);
				font-family: 'SF Pro Display';
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 120% */
				letter-spacing: -0.43px;
			}

			&-container {
				display: flex;
				padding-left: 16px;
				justify-content: flex-end;
				align-items: center;
				gap: 12px;
				align-self: stretch;
			}

			&-text {
				color: var(--tg-theme-hint-color);
				text-align: right;
				font-family: 'SF Pro Display';
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 120% */
				letter-spacing: -0.43px;
				max-width: 150px;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&-title {
		color: var(--tg-theme-hint-color);
		width: 100%;
		font-family: 'SF Pro Display';
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 14px; /* 116.667% */
		letter-spacing: -0.078px;
		text-transform: uppercase;
	}

	&-small-text {
		color: var(--tg-theme-hint-color);
		width: 100%;
		font-family: 'SF Pro Display';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px; /* 116.667% */
		letter-spacing: -0.078px;
	}

	&__group {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
		align-self: stretch;
	}

	&__large-group{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;
	}

	&-symbols {
		display: flex;
		width: 100%;
		padding: 3px 16px 0px 16px;
		flex-direction: column;
		align-items: flex-start;
		color: var(--tg-theme-hint-color);
		font-family: 'SF Pro Display';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 12px; /* 100% */
		letter-spacing: -0.078px;
	}
}
