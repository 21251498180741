.total-price {
	display: flex;
	padding: 16px;
    width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	border-top: 0.5px solid #e1e1ea;
	background-color: var(--tg-theme-secondary-bg-color);

	&__lines {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	&-line {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;

		&-text {
			color: #8f8fb2;
			font-family: 'SF Pro Display';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px; /* 128.571% */
			letter-spacing: -0.154px;

            span{
                color: #8f8fb2;
                font-family: 'SF Pro Display';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                letter-spacing: -0.154px;
            }
		}

        &-endtext{
            color: var(--tg-theme-text-color);
            font-family: 'SF Pro Display';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 120% */
            letter-spacing: 0.36px;
        }
	}
}
