.main-module {
	z-index: 100;

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		width: 100%;
		z-index: 100;
		animation: blink 2s;
	}

	&-bottom {
		position: relative;
		bottom: 15px;
		animation: blink 2s;
	}

	&__shake {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		z-index: 100;
		margin-top: 115px;
		margin-bottom: 145px;
		animation: blink 2s;

		&-title {
			color: #fff;
			font-family: 'SF Pro';
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 130%;
			letter-spacing: -0.442px;
			text-transform: uppercase;
			animation: blink 2s;
		}

		&-subtitle {
			color: #fff;
			font-family: 'SF Pro';
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.442px;
			text-transform: uppercase;
			animation: blink 2s;
		}
	}

	&__payment{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
	}
}