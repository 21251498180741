@import "../../styles/variables.scss";

.DAS-scrollbar{
    overflow: auto;
    width: 100%;
	scrollbar-width: thin;
	scrollbar-color: var(--tg-theme-hint-color) var(--tg-theme-text-color);
   
    &>::-webkit-scrollbar{
        background: var(--tg-theme-text-color);
        border-radius: 100px;
        padding-right: 10px!important;
        width: 5px!important;
        height: 90%!important;
    }

    &>::-webkit-scrollbar-button{
        padding: 0;
        display: none;
    }

    &>::-webkit-scrollbar-track{
        background-color: var(--tg-theme-text-color);
        border-radius: 100px;
        height: 90%!important;
    }

    &>::-webkit-scrollbar-thumb {
        background-color: var(--tg-theme-hint-color);
        border-radius: 100px;
    }
}