@import "normalize.css";

.non-background{
    background: transparent;
}

.container-item{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}
.cropper-move{
    border-radius: 50%;
}

.work{
    position: relative;
    display: flex;
    flex-direction: column;
}


