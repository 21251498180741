.payment-confirmed {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	align-self: stretch;
    padding-top: 20px;

	&-title {
		color: var(--tg-theme-text-color);
		text-align: center;
		font-family: 'SF Pro Display';
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px; /* 121.429% */
		letter-spacing: 0.38px;
	}

	&__block {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		align-self: stretch;
	}

	&-text {
		color: var(--tg-theme-hint-color);
		text-align: center;
		font-family: 'SF Pro Display';
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px; /* 120% */
		letter-spacing: -0.23px;
	}
}
