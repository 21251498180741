.heading-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 16px;
	// background: #fff;

	&__block {
		display: flex;
		min-height: 62px;
		flex-direction: column;
		align-items: flex-start;
		align-self: stretch;
		background-color: var(--tg-theme-bg-color);

		&:first-child {
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
		}
		&:last-child {
			border-bottom-left-radius: 16px;
			border-bottom-right-radius: 16px;
		}
	}

	&-devider {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		align-self: stretch;

		&-line {
			width: 100%;
			height: 0.32px;
			background: #e9ebea;
		}
	}

	&-left {
		display: flex;
		padding: 12px 20px;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		flex: 1 0 0;
		align-self: stretch;
	}

	&-right {
		display: flex;
		padding: 17px 13px 17px 10px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		gap: 10px;
		align-self: stretch;
	}

	&-content {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&-main {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1 0 0;
		align-self: stretch;
	}

	&-direction {
		flex-direction: row;
	}

	&-title {
		color: #8e8e93;
		font-family: 'SF Pro Display';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 14px;
	}

	&-description {
		color: var(--tg-theme-text-color);
		font-family: 'SF Pro Display';
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
	}

	&-link {
		color: var(--tg-theme-link-color);
		font-family: 'SF Pro Display';
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		cursor: pointer;

		&-image {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
