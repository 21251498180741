@import "../../styles/variables.scss";

.circle-preloader{
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&__circle {
		display: inline-block;
		width: 60px;
		height: 60px;

		&:after {
			content: " ";
			display: block;
			width: 30px;
			height: 30px;
			margin: 8px;
			border-radius: 50%;
			border: 6px solid $color-primary;
			border-color: $color-primary transparent $color-primary transparent;
			animation: spinner 1.5s linear infinite;
		}
	}
}

@keyframes spinner{
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}