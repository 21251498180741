.heading {
	display: flex;
	padding-bottom: 12px;
	flex-direction: column;
	align-items: center;

	&__block {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&-split {
		text-align: center;
		font-family: "SF Pro Display";
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
		background: var(--1, linear-gradient(180deg, #539ef9 0%, #61c5f9 100%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 108px;
		height: 108px;
		padding: 4px;
		border-radius: 1000px;
		border: 2px solid var(--tg-theme-link-color);
		box-sizing: border-box;

		img {
			width: 100px;
			height: 100px;
			border-radius: 50%;
		}
	}

	&-title {
		color: var(--tg-theme-text-color);
		text-align: center;
		font-family: 'SF Pro Display';
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px; /* 121.429% */
		letter-spacing: 0.38px;
	}

	&-description {
		color: var(--tg-theme-hint-color);
		text-align: center;
		font-family: 'SF Pro Display';
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 129.412% */
		letter-spacing: -0.43px;
	}
}
