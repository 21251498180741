.circle-block {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 108px;
	height: 108px;
	padding: 4px;
	border-radius: 1000px;
	border: 2px solid var(--tg-theme-button-color);
	box-sizing: border-box;

	div{
		width: 100%!important;
		height: 100%!important;
	}

	video{
		border-radius: 50%;
	}

	img {
		width: 100px;
		height: 100px;
		border-radius: 50%;
	}
}
